<template>
  <v-dialog v-model="dialog" width="750px" persistent style="height: 70vh">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom v-if="action == 'add'">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            class="msaBlue white--text"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="popData()"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </template>
        <span>New Template</span>
      </v-tooltip>
      <v-tooltip bottom v-if="action == 'edit'">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            text
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="popData()"
          >
            <v-icon color="white"> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </template>
    <v-card style="background-color: #ebf1fc">
      <v-card-title class="msaBlue white--text" v-if="action == 'add'">
        New Requirement Template
      </v-card-title>
      <v-card-title class="msaBlue white--text" v-if="action == 'edit'">
        Edit Requirement Template
      </v-card-title>
      <v-card-text
        class="white mx-auto mt-6 pb-8"
        style="width: 94%; border-radius: 4px"
      >
        <v-row>
          <v-col class="font-weight-bold pb-1 mt-1 msaBlue--text"> Name </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-text-field
              dense
              filled
              solo
              flat
              class="py-0"
              background-color="lightBg"
              required
              :rules="rules"
              counter
              maxlength="250"
              v-model="edited.templateName"
              data-testid="template-name"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="edited.groups.length > 0">
          <v-col cols="12">
            <v-expansion-panels multiple dark ref="groups">
              <draggable
                style="width: 100%"
                :list="edited.groups"
                @change="updateGroupPositions()"
                :disabled="
                  $vuetify.breakpoint.smAndDown || edited.groups.length == 0
                "
                @start="disabled = true"
                @end="disabled = false"
              >
                <v-expansion-panel
                  v-for="group in edited.groups"
                  :key="group.groupId"
                  class="msaBlue white--text my-2"
                  :disabled="disabled"
                >
                  <v-expansion-panel-header>
                    <v-row align="center">
                      <v-col class="shrink" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-icon class="white--text"> mdi-arrow-all </v-icon>
                      </v-col>
                      <v-col class="grow pl-0 text-body-2 font-weight-bold">
                        {{ group.requestGroupName }}
                        <br v-if="group.desctription != ''" />
                        <span
                          v-if="group.desctription != ''"
                          class="text-caption"
                        >
                          {{ group.description }}
                        </span>
                      </v-col>
                      <v-col class="shrink pr-0">
                        <SubTemplateRequestGroupDetail
                          :action="'edit'"
                          :group="group"
                          @saved="saveGroup($event)"
                          @close="reloadGroupDetail = $helpers.createGuid()"
                          :key="reloadGroupDetail"
                        />
                      </v-col>
                      <v-col class="shrink mr-2 pl-0">
                        <DeleteSubTemplateRequestGroup
                          @confirmed="deleteGroup(group)"
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    class="px-0 pb-0"
                    :key="reloadRequests"
                  >
                    <v-data-table
                      disable-filtering
                      disable-pagination
                      disable-sort
                      light
                      hide-default-footer
                      :headers="headers"
                      :items="group.requests"
                    >
                      <template v-slot:body="props">
                        <draggable
                          tag="tbody"
                          style="width: 100%"
                          group="requests"
                          :list="group.requests"
                          @change="updateRequestPositions($event, group)"
                          :disabled="
                            $vuetify.breakpoint.smAndDown ||
                            group.requests.length == 0
                          "
                        >
                          <tr v-for="item in props.items" :key="item.requestId">
                            <td>
                              {{ item.question }}
                            </td>
                            <td align="center">
                              {{ item.isFileRequired ? 'Yes' : 'No' }}
                            </td>
                            <td>
                              <SubTemplateRequestDetail
                                :action="'edit'"
                                :subRequest="item"
                                @saved="saveSubRequest($event, group)"
                              />
                              <DeleteRequest
                                :requestId="item.requestId"
                                @deleted="deleteRequest($event, group)"
                              />
                            </td>
                          </tr>
                        </draggable>
                      </template>
                      <template v-slot:footer>
                        <v-container fluid>
                          <v-row align="center">
                            <v-col>
                              <SubTemplateRequestDetail
                                :action="'add'"
                                :subRequest="{
                                  question: '',
                                  isFileRequired: false,
                                }"
                                @saved="saveSubRequest($event, group)"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </draggable>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <SubTemplateRequestGroupDetail
              :action="'add'"
              @saved="addGroup($event)"
              :group="{
                requestGroupName: '',
                requests: [],
                deletedBy: '',
                description: '',
              }"
              @close="reloadGroupDetail = $helpers.createGuid()"
              :key="reloadGroupDetail"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row align="center">
            <v-col align="right" class="grow">
              <v-btn
                plain
                class="msaBlue--text font-weight-bold"
                @click="close()"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col align="right" class="shrink">
              <v-btn
                class="msaBlue white--text"
                @click="submit()"
                :disabled="disableSave"
                data-testid="template-save-button"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import SubTemplateRequestGroupDetail from '@/components/SubTemplates/SubTemplateRequestGroupDetail.vue';
import DeleteSubTemplateRequestGroup from '@/components/SubTemplates/DeleteSubTemplateRequestGroup.vue';
import SubTemplateRequestDetail from '@/components/SubTemplates/SubTemplateRequestDetail.vue';
import DeleteRequest from '@/components/SubTemplates/DeleteRequest.vue';
import draggable from 'vuedraggable';
export default {
  name: 'SubTemplateDetail',
  components: {
    SubTemplateRequestGroupDetail,
    DeleteSubTemplateRequestGroup,
    SubTemplateRequestDetail,
    DeleteRequest,
    draggable,
  },
  props: {
    action: {
      type: String,
    },
    subTemplateId: {
      type: Number,
    },
  },
  computed: {
    disableSave() {
      return (
        JSON.stringify(this.subTemplate) == JSON.stringify(this.edited) ||
        this.edited.templateName.trim().length == 0 ||
        this.edited.groups.length == 0
      );
    },
  },
  data() {
    return {
      dialog: false,
      reloadRequests: '',
      reloadGroupDetail: '',
      subTemplate: { templateName: '', groups: [] },
      edited: { templateName: '', groups: [] },
      deleted: { groups: [], requests: [] },
      rules: [(v) => !!v.toString().trim() || 'This field is required.'],
      disabled: false,
      headers: [
        {
          text: 'Question',
          value: 'question',
          class: 'lightBg msaBlue--text',
        },
        {
          text: 'Requires Document',
          value: 'isFileRequired',
          class: 'lightBg msaBlue--text',
          width: '80px',
          align: 'center',
        },
        {
          text: 'Actions',
          class: 'lightBg msaBlue--text',
          width: '140px',
          align: 'center',
        },
      ],
    };
  },
  methods: {
    popData() {
      if (this.action == 'edit') {
        this.getSubTemplateRequestsInGroups();
      }

      if (this.action == 'add') {
        this.subTemplate = { templateName: '', groups: [] };
        this.edited = JSON.parse(JSON.stringify(this.subTemplate));
      }
    },
    getSubTemplateRequestsInGroups() {
      const params = {
        subTemplateId: this.subTemplateId,
        loaderText: 'loading...',
      };

      const url = 'get-sub-template-requests-in-groups?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.subTemplate = response.data;
          this.edited = JSON.parse(JSON.stringify(this.subTemplate));
        })
        .catch((error) => error);
    },
    updateGroupPositions() {
      this.edited.groups.forEach(
        (group, index) => (group.groupPosition = index + 1),
      );
    },
    submit() {
      const url = 'save-sub-template?format=json';
      const params = {
        subTemplate: this.edited,
        deleted: this.deleted,
        loaderText: 'saving...',
      };

      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('saved');
          this.close();
        })
        .catch((error) => error);
    },
    close() {
      this.$emit('close');
      this.dialog = false;
    },
    deleteGroup(group) {
      const index = this.edited.groups.findIndex(
        (g) => g.groupId == group.groupId,
      );
      if (!isNaN(group.groupId)) {
        this.deleted.groups.push(this.edited.groups[index]);
      }
      this.edited.groups.splice(index, 1);
      this.updateGroupPositions();
    },
    updateRequestPositions(e, group) {
      if (e != undefined) {
        if (e.added != undefined) {
          e.added.element.groupId = group.groupId;
        }
      }

      group.requests.forEach((r, i) => {
        r.requestPosition = i + 1;
      });
    },
    saveSubRequest(subRequest, group) {
      if (subRequest.requestId == undefined) {
        subRequest.requestId = this.$helpers.createGuid();
        group.requests.push(subRequest);
      } else {
        const index = group.requests.findIndex(
          (r) => r.requestId == subRequest.requestId,
        );
        group.requests.splice(index, 1, subRequest);
      }
      group.requests.forEach((r, i) => (r.requestPosition = i + 1));
      this.reloadRequests = this.$helpers.createGuid();
    },
    saveGroup(group) {
      const index = this.edited.groups.findIndex(
        (g) => g.groupId == group.groupId,
      );
      this.edited.groups[index].requestGroupName = group.requestGroupName;
      this.edited.groups[index].description = group.description;
    },
    deleteRequest(requestId, group) {
      const index = group.requests.findIndex((r) => r.requestId == requestId);
      if (!isNaN(requestId)) {
        this.deleted.requests.push(group.requests[index]);
      }
      group.requests.splice(index, 1);
      group.requests.forEach((r, i) => (r.requestPosition = i + 1));
    },
    addGroup(group) {
      group.groupPosition = this.edited.groups.length + 1;
      this.edited.groups.push(group);
      this.$nextTick(() => {
        const nodes = this.$refs.groups.$el.querySelectorAll(
          'button.v-expansion-panel-header',
        );
        const lastNode = nodes[nodes.length - 1];
        lastNode.click();
      });
    },
  },
};
</script>
