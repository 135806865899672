<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            small
            text
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon color="red"> mdi-delete </v-icon>
          </v-btn>
        </template>
        <span>delete</span>
      </v-tooltip>
    </template>

    <v-card sm="12" md="7" xs="12">
      <v-card-title class="headline msaBlue white--text">
        Delete Group
      </v-card-title>
      <v-card-text>
        <h3 class="pt-2 mt-2">Are you sure you want to delete this group?</h3>
        <p>
          Deleting a group will also delete all of it's requirements. Do you
          still want to delete this group?
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="msaBlue" text @click="close()"> Close </v-btn>
        <v-btn
          color="msaBlue white--text"
          @click="
            $emit('confirmed');
            close();
          "
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'DeleteSubTemplateRequestGroup',
  data() {
    return { dialog: false };
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
};
</script>
