<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-btn
        v-if="action == 'add'"
        class="msaBlue white--text"
        small
        v-bind="attrs"
        v-on="{ ...dialog }"
        v-blur
        @click="popData()"
        width="100%"
      >
        <v-icon class="pr-2"> mdi-plus </v-icon>
        New Requirement
      </v-btn>
      <v-tooltip bottom v-if="action == 'edit'">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            small
            text
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="popData()"
          >
            <v-icon color="msaBlue"> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text" v-if="action == 'add'">
        New Requirement
      </v-card-title>
      <v-card-title class="msaBlue white--text" v-if="action == 'edit'">
        Edit Requirement
      </v-card-title>
      <v-card-text class="pt-4 pb-0">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field
              dense
              class="py-0"
              required
              :rules="rules"
              counter
              maxlength="250"
              v-model="edited.question"
              label="Question"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-checkbox
              dense
              class="py-0"
              v-model="edited.isFileRequired"
              label="Requires Document"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain class="msaBlue--text font-weight-bold" @click="close()">
          Cancel
        </v-btn>

        <v-btn
          class="msaBlue white--text"
          @click="submit()"
          :disabled="disableSave"
          data-testid="requirement-save-button"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'SubTemplateRequestDetail',
  props: {
    action: {
      type: String,
    },
    subRequest: {
      type: Object,
    },
  },
  computed: {
    disableSave() {
      return (
        (this.subRequest.question == this.edited.question &&
          this.subRequest.isFileRequired == this.edited.isFileRequired) ||
        this.edited.question.toString().trim().length == 0
      );
    },
  },
  data() {
    return {
      dialog: false,
      edited: { question: '', isFileRequired: false, deletedBy: '' },
      rules: [(v) => !!v.toString().trim() || 'This field is required.'],
    };
  },
  methods: {
    popData() {
      if (this.action == 'edit') {
        this.edited.requestId = this.subRequest.requestId;
      }

      this.edited.question = this.subRequest.question;
      this.edited.isFileRequired = this.subRequest.isFileRequired;
    },
    submit() {
      this.$emit('saved', this.edited);
      this.close();
    },
    close() {
      this.$emit('close');
      this.dialog = false;
    },
  },
};
</script>
