<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-btn
        v-if="action == 'add'"
        class="msaBlue--text"
        v-bind="attrs"
        v-on="{ ...dialog }"
        v-blur
        width="100%"
        height="64px"
        outlined
        style="border-style: dashed"
        @click="popData()"
      >
        <v-icon> mdi-plus-box </v-icon>
        {{ $vuetify.breakpoint.smAndDown ? 'A' : 'Click here to a' }}dd a new
        group
      </v-btn>
      <v-tooltip bottom v-if="action == 'edit'">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            text
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="popData()"
          >
            <v-icon color="white"> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text" v-if="action == 'add'">
        Add New Group
      </v-card-title>
      <v-card-title class="msaBlue white--text" v-if="action == 'edit'">
        Edit Group
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="mt-4 font-weight-bold"> Name: </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-text-field
              class="py-0"
              required
              counter
              dense
              maxlength="250"
              outlined
              v-model="edited.requestGroupName"
              :rules="rules"
              data-testid="group-name"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="mt-4 font-weight-bold"> Description: </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-text-field
              class="py-0"
              counter
              dense
              maxlength="250"
              outlined
              v-model="edited.description"
              data-testid="group-description"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="right" class="grow">
            <v-btn
              plain
              class="msaBlue--text font-weight-bold"
              @click="close()"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col align="right" class="shrink">
            <v-btn
              class="msaBlue white--text"
              :disabled="disableSave"
              @click="save()"
              data-testid="group-save-button"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'SubTemplateRequestGroupDetail',
  props: {
    group: {
      type: Object,
    },
    action: {
      type: String,
    },
  },
  computed: {
    disableSave() {
      return (
        this.edited.requestGroupName.length == 0 ||
        (this.edited.requestGroupName == this.group.requestGroupName &&
          this.edited.description == this.group.description)
      );
    },
  },
  data() {
    return {
      edited: { requestGroupName: '', requests: [] },
      dialog: false,
      rules: [(v) => !!v.toString().trim() || 'This field is required.'],
    };
  },
  methods: {
    popData() {
      if (this.action == 'add') {
        this.edited.groupId = this.$helpers.createGuid();
        this.edited.requestGroupName = '';
        this.edited.requests = [];
        this.edited.description = '';
      }

      if (this.action == 'edit') {
        this.edited = JSON.parse(JSON.stringify(this.group));
      }
    },
    close() {
      this.dialog = false;
      this.$emit('close');
    },
    save() {
      this.$emit('saved', this.edited);
      this.close();
    },
  },
  mounted() {},
};
</script>
<style scoped></style>
