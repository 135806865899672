<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            small
            text
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon color="red"> mdi-delete </v-icon>
          </v-btn>
        </template>
        <span>delete</span>
      </v-tooltip>
    </template>

    <v-card sm="12" md="7" xs="12">
      <v-card-title class="headline msaBlue white--text">
        Delete Requirement
      </v-card-title>
      <v-card-text>
        Are you sure you want to delete this requirement?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="msaBlue" text @click="close()"> Close </v-btn>
        <v-btn color="msaBlue white--text" @click="deleteSubRequest()">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'DeleteRequest',
  props: {
    requestId: {
      type: [Number, String],
    },
  },
  data() {
    return { dialog: false };
  },
  methods: {
    deleteSubRequest() {
      this.$emit('deleted', this.requestId);
      this.close();
    },
    close() {
      this.$emit('close');
      this.dialog = false;
    },
  },
};
</script>
