<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" md="6">
        <SearchBar @search="search = $event" searchLabel="Search by title..." />
      </v-col>
      <v-col align="right" class="py-0">
        <SubTemplateDetail
          :action="'add'"
          @saved="getSubTemplates()"
          :key="reload"
          @close="reload = !reload"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-expansion-panels multiple v-model="expanded">
          <draggable
            style="width: 100%"
            :list="subTemplates"
            :disabled="search.length > 0 || $vuetify.breakpoint.smAndDown"
            @change="updatePositions()"
            @start="disabled = true"
            @end="disabled = false"
          >
            <v-expansion-panel
              :disabled="disabled"
              v-for="(template, index) in subTemplates"
              :key="index"
              class="msaBlue mb-4"
            >
              <v-expansion-panel-header
                hide-actions
                v-slot="{ open }"
                :style="headerStyles(index)"
              >
                <v-row align="center">
                  <v-col
                    class="shrink"
                    v-if="$vuetify.breakpoint.mdAndUp && search.length == 0"
                  >
                    <v-icon
                      style="flex: unset"
                      :class="`${open ? 'white--text' : ''} pr-4`"
                    >
                      mdi-arrow-all
                    </v-icon>
                  </v-col>
                  <v-col
                    :class="`${open ? 'white--text' : ''} font-weight-medium`"
                  >
                    {{ template.templateName }}
                  </v-col>
                  <v-col class="shrink pr-0">
                    <SubTemplateDetail
                      v-if="open"
                      :action="'edit'"
                      @saved="reloadPreview = $helpers.createGuid()"
                      :key="reload"
                      @close="reload = !reload"
                      :subTemplateId="template.id"
                    />
                  </v-col>
                  <v-col class="shrink mr-2 pl-0">
                    <DeleteSubTemplate
                      v-if="open"
                      :subTemplateId="template.id"
                      @deleted="getSubTemplates()"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="white black--text">
                <SubTemplatePreview
                  :subTemplateId="template.id"
                  :key="reloadPreview"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </draggable>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Draggable from 'vuedraggable';
import SearchBar from '@/components/SearchBar.vue';
import SubTemplateDetail from '@/components/SubTemplates/SubTemplateDetail.vue';
import DeleteSubTemplate from '@/components/SubTemplates/DeleteSubTemplate.vue';
import SubTemplatePreview from '@/components/SubTemplates/SubTemplatePreview.vue';
export default {
  name: 'RequirementTemplates',
  components: {
    SearchBar,
    SubTemplateDetail,
    DeleteSubTemplate,
    SubTemplatePreview,
    Draggable,
  },
  computed: {
    groupLength() {
      return this.subTemplates.length;
    },
    headerStyles() {
      return (index) =>
        this.expanded.findIndex((i) => i == index) == -1
          ? {
              backgroundImage:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            }
          : {};
    },
  },
  watch: {
    search: function () {
      this.expanded = [];
      this.getSubTemplates();
    },
    groupLength() {
      if (this.search == null || this.search == '') {
        this.updatePositions();
      }
    },
  },
  data() {
    return {
      reload: false,
      subTemplates: [],
      search: '',
      disabled: false,
      reloadPreview: '',
      expanded: [],
    };
  },
  methods: {
    getSubTemplates() {
      if (this.search == null) {
        this.search = '';
      }

      const params = {
        search: this.search,
        loaderText: 'loading...',
      };

      const url = 'get-sub-templates?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.subTemplates = response.data;
        })
        .catch((error) => error);
    },
    updatePositions() {
      this.subTemplates.forEach(
        (template, index) => (template.position = index + 1),
      );
      const url = 'update-sub-template-positions?format=json';
      const params = { subTemplates: this.subTemplates };
      this.$axios.post(url, params).catch((e) => e);
    },
  },
  mounted() {
    this.getSubTemplates();
  },
};
</script>
<style scoped></style>
