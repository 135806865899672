<template>
  <ul class="pl-0" style="list-style: none; width: 100%">
    <li
      v-for="group in subTemplate.groups"
      :key="group.groupId"
      style="border-bottom: 1px solid grey"
      class="lightGrey"
    >
      <div class="font-weight-bold px-4 py-2 headerTextGrey--text">
        {{ group.requestGroupName }}
        <br v-if="group.description.length > 0" />
        <div
          v-if="group.description.length > 0"
          class="font-weight-normal text-caption"
        >
          {{ group.description }}
        </div>
      </div>
      <ul style="list-style: none" class="white">
        <li v-for="request in group.requests" :key="request.requestId">
          - {{ request.question }}
          <span v-if="request.isFileRequired"> (Document Required) </span>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SubTemplatePreview',
  props: {
    subTemplateId: {
      type: Number,
    },
  },
  data() {
    return { subTemplate: {} };
  },
  methods: {
    getSubTemplateRequestsInGroups() {
      const params = {
        subTemplateId: this.subTemplateId,
        loaderText: 'loading...',
      };

      const url = 'get-sub-template-requests-in-groups?format=json';

      this.$axios
        .post(url, params)
        .then(response => {
          this.subTemplate = response.data;
        })
        .catch(error => error);
    },
  },
  mounted() {
    this.getSubTemplateRequestsInGroups();
  },
};
</script>

<style></style>
